@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
*[hidden] { display: none; }

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: auto;

    src: local('Material Icons'),
         local('MaterialIcons-Regular'),
         url(/assets/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
         url(/assets/iconfont/MaterialIcons-Regular.woff) format('woff'),
         url(/assets/iconfont/MaterialIcons-Regular.ttf) format('truetype');
  }
  /* Theme Nova-Light Primeng */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  touch-action:pan-y;
}

mat-tab-group{
  background-color: #ff6400;max-width:960px!important;margin:0 auto;min-height:800px
}
mat-toolbar.padding-left-4 {
  padding-left: 4rem; 
}
.flex-6 {
  display: flex;
  flex: 0 0 6%; 
}
@media screen and (max-width: 599px) {

  /* xs: max-width 576px */
  .flex-9-xs {
    flex: 0 0 9%;
  }

  .padding-left-4-xs {
    padding-left: 4%;
  }
}

@media (min-width: 600px) and (max-width: 959px) {

  /* sm: max-width 768px */
  .padding-left-4-sm {
    padding-left: 4%;
  }
}




body kruh-root {
  display: flex;
  flex-direction: column;
  height: 98vh;
  align-items: stretch;
  align-content: stretch;
}

body html{
  overflow: hidden!important;
  scroll-behavior: smooth;
}
.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: hidden!important;
}
.mat-homepage-spinner{
  z-index: 0;
}
.caret-down {
  display: block;
  margin: 0px auto;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fdc8c8;
}
#ios_install_prompt, #android_back_prompt {
  display: block;
  position: absolute;
  bottom: 5px;
  left: -120%;
  width: 98%;
  margin-left: 1%;
  z-index: 1001;
  transition: left .5s;
}
#ios_install_prompt.active, #android_back_prompt.active {
  left: 0;
  bottom: 10;
}
.prompt-message {
  display: block;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  background-color: #fdc8c8;
  font-size: .8rem;
  line-height: 1.3;
  text-align: center;
  white-space: pre-line;
}

app-toolbar {font-size: 14px;}
.padding-left-4 {
padding-left: 4px!important;
padding-right: 4px!important;
}
.ui-button-icon-only .ui-button-icon-left,.ui-button-text-icon-left .ui-button-icon-left,.ui-button-text-icon-right .ui-button-icon-right {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  height: 1em
}
.mat-toolbar.mat-primary {
  background: #ffffff00!important;
  color: rgb(240, 240, 240)!important;
  /* text-shadow: 0px 0px 2px black; */
}
.mat-toolbar-row, .mat-toolbar-single-row {
  height: 59px!important;
  padding: 0!important;
}
.mat-tab-body-content{
  overflow-y: hidden!important;
}
.bars{
  padding-left: 16px;
}
kruh-broadcast > p-card > div {
  margin: 0 auto;
  height: 86vh;
  width: 85%;
}
kruh-push > p-card > div {
  margin: 0 auto;
  height: 86vh;
  width: 85%;
}
kruh-ballmertshofen > p-card > div > div.p-card-body > div,kruh-contact > p-card > div > div.p-card-body > div,kruh-links > p-card > div > div.p-card-body > div{
  height: 77vh;
}
app-photos > p-card > div > div.p-card-body > div{
  padding: 0!important;
}
 kruh-program-details-dialog > div > div > div.filmdetailinforechts > div.fsk > img{
  width: 60px;
 }

 kruh-program .kind {
  font-size: x-large;font-weight: 500;
  background-color: #ffdebf!important;
  margin-bottom: 8px;
  border: #e26e2b;
  border-width: thick;
  border-style: dotted;
}





/* kruh-editorial  > p-card > div > div.p-card-body{
   height: 77vh;
   padding: 1rem!important;
   overflow-y: auto;
 } */
  kruh-editorial > p-card > div > div.p-card-body{
    padding: 0 0 0 1rem!important;
  }
 kruh-editorial > p-card > div > div.p-card-body > div > div > div{
  height: 72vh;
  overflow-y: auto;
  padding-right: 1.5rem;
 }
 kruh-editorial > p-card > div > div.p-card-body > div{
  height: 74vh;
}
kruh-editorial .yellow{
  background-color: lime;
}
.yellow{
  background-color: lime;
}
/* kruh-about > p-card > div > div.p-card-body > div{
    padding: 0 0 0 1rem!important;
    height: 100%;
} */
kruh-about > p-card > div > div.p-card-body,kruh-datenschutz > p-card > div > div.p-card-body,kruh-ballmertshofen > p-card > div > div.p-card-body{
  height: 77vh;
  padding: 0 0 0 1rem!important;
}

kruh-about > p-card > div > div.p-card-body > div,kruh-datenschutz > p-card > div > div.p-card-body > div,kruh-ballmertshofen > p-card > div > div.p-card-body > div{
  padding: 0 1rem 0 0!important;
  height: 77vh;
  overflow-y: auto;
}


.btn{margin: 20px;}
div.btn > p-button > button{
    padding: 20px!important;
    width: 200px;
}
#subscribe > span:nth-child(1) > fa-icon,#unsubscribe > span:nth-child(1) > fa-icon{
  margin-right: 10px;
}


#map {
  position: relative;
  height: 74vh!important;
  width: calc(100%)!important;
}


body .ui-widget {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  text-decoration: none;
}
/* body .ui-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
} */
body a {
  color: #007ad9;
  text-decoration: none;
}
body a:hover {
  color: #116fbf;
}
body a:active {
  color: #005b9f;
}
body .ui-helper-reset {
  line-height: normal;
}
body .ui-state-disabled, body .ui-widget:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
body .pi {
  font-size: 1.25em;
}

body .ui-inputtext {
  font-size: 14px;
  color: #333333;
  background: #fff;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
body .ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-color: #212121;
}
body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-color: #007ad9;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-chkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}
body .ui-chkbox .ui-chkbox-box {
  border: 1px solid #a6a6a6;
  background-color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
body .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover {
  border-color: #212121;
}
body .ui-chkbox .ui-chkbox-box.ui-state-focus {
  border-color: #007ad9;
  background-color: #fff;
  color: #007ad9;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: #007ad9;
  background-color: #007ad9;
  color: #fff;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #005b9f;
  background-color: #005b9f;
  color: #fff;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-focus {
  border-color: #007ad9;
  background-color: #007ad9;
  color: #fff;
}
body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 18px;
}
body .ui-chkbox-label {
  margin: 0 0 0 0.5em;
}
body .ui-radiobutton {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}
body .ui-radiobutton .ui-radiobutton-box {
  border: 1px solid #a6a6a6;
  background-color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .ui-radiobutton .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #212121;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
  border-color: #007ad9;
  background-color: #fff;
  color: #007ad9;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #007ad9;
  background-color: #007ad9;
  color: #fff;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
  background-color: #fff;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #005b9f;
  background-color: #005b9f;
  color: #fff;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-focus {
  border-color: #007ad9;
  background-color: #007ad9;
  color: #fff;
}
body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
  background: transparent;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -5px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon:before {
  display: none;
}
body .ui-radiobutton-label {
  margin: 0 0 0 0.5em;
}
body .ui-inputswitch {
  width: 3em;
  height: 1.75em;
}
body .ui-inputswitch .ui-inputswitch-slider {
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border-radius: 30px;
  background: #cccccc;
}
body .ui-inputswitch .ui-inputswitch-slider:before {
  background-color: #fff;
  height: 1.25em;
  width: 1.25em;
  left: 0.25em;
  bottom: 0.25em;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}
body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #b7b7b7;
}
body .ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #b7b7b7;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: #007ad9;
}
body .ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #116fbf;
}
body .ui-inputswitch.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #005b9f;
}
body .ui-autocomplete .ui-autocomplete-input {
  padding: 0.429em;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
  padding: 0.2145em 0.429em;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled):hover {
  border-color: #212121;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
  border-color: #007ad9;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
  margin: 0;
  padding: 0.2145em 0;
  color: #333333;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
  font-size: 14px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #007ad9;
  color: #fff;
}
body .ui-autocomplete-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-autocomplete-panel .ui-autocomplete-items {
  padding: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
  color: #fff;
  background-color: #007ad9;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-group {
  padding: 0.429em 0.857em;
  background-color: #d8dae2;
  color: #333333;
}
body .ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
  width: 2.357em;
}
body .ui-fluid .ui-autocomplete.ui-autocomplete-multiple.ui-autocomplete-dd .ui-autocomplete-multiple-container {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}
body .ui-fluid .ui-autocomplete.ui-autocomplete-dd .ui-inputtext {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}
body .ui-chips > ul.ui-inputtext {
  padding: 0.2145em 0.429em;
  display: inline-block;
}
body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled):hover {
  border-color: #212121;
}
body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled).ui-state-focus {
  border-color: #007ad9;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token {
  padding: 0.2145em 0;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #333333;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:hover {
  border: 0 none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:focus {
  border: 0 none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-token {
  font-size: 14px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #007ad9;
  color: #fff;
}
body .ui-dropdown {
  background: #fff;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-dropdown:not(.ui-state-disabled):hover {
  border-color: #212121;
}
body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
  border-color: #007ad9;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-dropdown .ui-dropdown-label {
  padding-right: 2em;
}
body .ui-dropdown .ui-dropdown-trigger {
  background-color: #fff;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #848484;
}
body .ui-dropdown .ui-dropdown-clear-icon {
  color: #848484;
}
body .ui-dropdown.ui-dropdown-clearable .ui-dropdown-label {
  padding-right: 4em;
}
body .ui-dropdown-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-dropdown-panel .ui-dropdown-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: #fff;
  margin: 0;
}
body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter {
  width: 100%;
  padding-right: 2em;
}
body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  right: 1.357em;
  color: #007ad9;
}
body .ui-dropdown-panel .ui-dropdown-items {
  padding: 0;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
  color: #fff;
  background-color: #007ad9;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #333333;
  background-color: #eaeaea;
}
body .ui-multiselect {
  background: #fff;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #212121;
}
body .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
  border-color: #007ad9;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-multiselect .ui-multiselect-label {
  padding: 0.429em;
  padding-right: 2em;
  color: #333333;
}
body .ui-multiselect .ui-multiselect-trigger {
  background-color: #fff;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #848484;
}
body .ui-multiselect-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-multiselect-panel .ui-multiselect-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: #fff;
  margin: 0;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container {
  float: none;
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-inputtext {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
  color: #007ad9;
  top: 50%;
  margin-top: -0.5em;
  right: 0.5em;
  left: auto;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-chkbox {
  margin-right: 0.5em;
  float: none;
  vertical-align: middle;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close {
  color: #848484;
  top: 50%;
  margin-top: -0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:hover {
  color: #333333;
}
body .ui-multiselect-panel .ui-multiselect-items {
  padding: 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  color: #fff;
  background-color: #007ad9;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #333333;
  background-color: #eaeaea;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box {
  cursor: auto;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box:hover {
  border: 1px solid #a6a6a6;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box.ui-state-active:hover {
  border-color: #007ad9;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}
body .ui-listbox {
  padding: 0;
  min-width: 12em;
  background: #fff;
  border: 1px solid #a6a6a6;
}
body .ui-listbox .ui-chkbox {
  margin: 0 0.5em 0 0;
}
body .ui-listbox .ui-listbox-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: #fff;
  margin: 0;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
  width: calc(100% - (0.857em + 0.857em + 0.5em));
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container input {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.5em;
  color: #007ad9;
}
body .ui-listbox .ui-listbox-list {
  background-color: #fff;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item.ui-state-highlight {
  color: #fff;
  background-color: #007ad9;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item label {
  display: inline-block;
  vertical-align: middle;
}
body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #333333;
  background-color: #eaeaea;
}
body .ui-listbox.ui-state-disabled .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border: 1px solid #a6a6a6;
}
body .ui-listbox .ui-listbox-footer {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-top: 1px solid #eaeaea;
  color: #333333;
  background-color: #fff;
}
body .ui-editor-container .ui-editor-toolbar {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
}
body .ui-editor-container .ui-editor-content {
  border: 1px solid #c8c8c8;
}
body .ui-editor-container .ui-editor-content .ql-editor {
  background-color: #fff;
  color: #333333;
}
body .ui-editor-container .ql-picker.ql-expanded .ql-picker-label {
  color: #333333;
}
body .ui-editor-container .ql-stroke {
  stroke: #333333;
}
body .ui-editor-container .ql-picker-label {
  color: #333333;
}
body .ui-editor-container .ql-snow.ql-toolbar button:hover,
body .ui-editor-container .ql-snow .ql-toolbar button:hover,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #007ad9;
}
body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #007ad9;
}
body .ui-rating a {
  text-align: center;
  display: inline-block;
  color: #333333;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-rating a.ui-rating-cancel {
  color: #e4018d;
}
body .ui-rating a .ui-rating-icon {
  font-size: 20px;
}
body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:hover {
  color: #007ad9;
}
body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a.ui-rating-cancel:hover {
  color: #b5019f;
}
body .ui-spinner .ui-spinner-input {
  padding-right: 2.429em;
}
body .ui-spinner .ui-spinner-button {
  width: 2em;
}
body .ui-spinner .ui-spinner-button.ui-spinner-up {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-spinner .ui-spinner-button.ui-spinner-down {
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}
body .ui-fluid .ui-spinner .ui-spinner-input {
  padding-right: 2.429em;
}
body .ui-fluid .ui-spinner .ui-spinner-button {
  width: 2em;
}
body .ui-fluid .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  left: 50%;
}
body .ui-slider {
  background-color: #c8c8c8;
  border: 0 none;
}
body .ui-slider.ui-slider-horizontal {
  height: 0.286em;
}
body .ui-slider.ui-slider-horizontal .ui-slider-handle {
  top: 50%;
  margin-top: -0.5715em;
}
body .ui-slider.ui-slider-vertical {
  width: 0.286em;
}
body .ui-slider.ui-slider-vertical .ui-slider-handle {
  left: 50%;
  margin-left: -0.5715em;
}
body .ui-slider .ui-slider-handle {
  height: 1.143em;
  width: 1.143em;
  background-color: #fff;
  border: 2px solid #666666;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-slider .ui-slider-range {
  background-color: #007ad9;
}
body .ui-slider:not(.ui-state-disabled) .ui-slider-handle:hover {
  background-color: 2px solid #666666;
  border: 2px solid #007ad9;
}
body .ui-datepicker {
  padding: 0.857em;
  min-width: 20em;
  background-color: #fff;
  color: #333333;
  border: 1px solid #a6a6a6;
}
body .ui-datepicker:not(.ui-datepicker-inline) {
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:hover,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:hover {
  color: #007ad9;
}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:focus,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:focus {
  outline: 0 none;
  color: #007ad9;
}
body .ui-datepicker:not(.ui-state-disabled) table td a:not(.ui-state-active):not(.ui-state-highlight):hover {
  background-color: #eaeaea;
}
body .ui-datepicker:not(.ui-state-disabled) .ui-monthpicker a.ui-monthpicker-month:not(.ui-state-active):hover {
  background-color: #eaeaea;
}
body .ui-datepicker .ui-datepicker-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  background-color: #fff;
  color: #333333;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  cursor: pointer;
  top: 0;
  color: #a6a6a6;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  margin: 0;
  padding: 0;
  line-height: 1;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
  margin-top: -0.35em;
  margin-bottom: 0;
}
body .ui-datepicker table {
  font-size: 14px;
  margin: 0.857em 0 0 0;
}
body .ui-datepicker table th {
  padding: 0.5em;
}
body .ui-datepicker table td {
  padding: 0.5em;
}
body .ui-datepicker table td > a, body .ui-datepicker table td > span {
  display: block;
  text-align: center;
  color: #333333;
  padding: 0.5em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-datepicker table td > a.ui-state-active, body .ui-datepicker table td > span.ui-state-active {
  color: #fff;
  background-color: #007ad9;
}
body .ui-datepicker table td > a {
  cursor: pointer;
}
body .ui-datepicker table td.ui-datepicker-today > a, body .ui-datepicker table td.ui-datepicker-today > span {
  background-color: #d0d0d0;
  color: #333333;
}
body .ui-datepicker table td.ui-datepicker-today > a.ui-state-active, body .ui-datepicker table td.ui-datepicker-today > span.ui-state-active {
  color: #fff;
  background-color: #007ad9;
}
body .ui-datepicker .ui-datepicker-buttonbar {
  border-top: 1px solid #d8dae2;
}
body .ui-datepicker .ui-timepicker {
  border: 0 none;
  border-top: 1px solid #d8dae2;
  padding: 0.857em;
}
body .ui-datepicker .ui-timepicker a {
  color: #333333;
  font-size: 1.286em;
}
body .ui-datepicker .ui-timepicker a:hover {
  color: #007ad9;
}
body .ui-datepicker .ui-timepicker span {
  font-size: 1.286em;
}
body .ui-datepicker .ui-monthpicker .ui-monthpicker-month {
  color: #333333;
}
body .ui-datepicker .ui-monthpicker .ui-monthpicker-month.ui-state-active {
  color: #fff;
  background-color: #007ad9;
}
body .ui-datepicker.ui-datepicker-timeonly {
  padding: 0;
}
body .ui-datepicker.ui-datepicker-timeonly .ui-timepicker {
  border-top: 0 none;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group {
  border-right: 1px solid #d8dae2;
  padding-right: 0.857em;
  padding-left: 0.857em;
  padding-top: 0;
  padding-bottom: 0;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:first-child {
  padding-left: 0;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:hover:not(.ui-state-error), body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  width: 2.357em;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
body .ui-fluid .ui-calendar.ui-calendar-w-btn input.ui-inputtext {
  width: calc(100% - 2.357em);
}
body .ui-fileupload .ui-fileupload-buttonbar {
  background-color: #f4f4f4;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
  border-bottom: 0 none;
}
body .ui-fileupload .ui-fileupload-buttonbar .ui-button {
  margin-right: 8px;
}
body .ui-fileupload .ui-fileupload-content {
  background-color: #fff;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
}
body .ui-fileupload .ui-progressbar {
  top: 0;
}
body .ui-fileupload-choose:not(.ui-state-disabled):hover {
  background-color: #116fbf;
  color: #fff;
  border-color: #116fbf;
}
body .ui-fileupload-choose:not(.ui-state-disabled):active {
  background-color: #005b9f;
  color: #fff;
  border-color: #005b9f;
}
body .ui-fileupload-choose.ui-state-focus {
  outline: 0 none;
  outline-offset: 0px;
}
body .ui-password-panel {
  padding: 12px;
  background-color: #fff;
  color: #333333;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-inputgroup .ui-inputgroup-addon {
  border-color: #a6a6a6;
  background-color: #eaeaea;
  color: #848484;
  padding: 0.429em;
  min-width: 2em;
}
body .ui-inputgroup .ui-inputgroup-addon:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-inputgroup .ui-inputgroup-addon:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox {
  position: relative;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox .ui-chkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton {
  position: relative;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton .ui-radiobutton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
body .ui-inputgroup .ui-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-inputgroup .ui-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-fluid .ui-inputgroup .ui-button {
  width: auto;
}
body .ui-fluid .ui-inputgroup .ui-button.ui-button-icon-only {
  width: 2.357em;
}
body ::-webkit-input-placeholder {
  color: #666666;
}
body :-moz-placeholder {
  color: #666666;
}
body ::-moz-placeholder {
  color: #666666;
}
body :-ms-input-placeholder {
  color: #666666;
}
body .ui-inputtext.ng-dirty.ng-invalid,
body p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
body p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
body p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
body p-chips.ng-dirty.ng-invalid > .ui-inputtext,
body p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
body p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
body p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
body p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
body p-listbox.ng-dirty.ng-invalid .ui-inputtext,
body p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
body p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
body p-selectbutton.ng-dirty.ng-invalid .ui-button,
body p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border: 1px solid #a80000;
}

body .ui-button {
  margin: 0;
  color: #fff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-button:enabled:hover {
  background-color: #116fbf;
  color: #fff;
  border-color: #116fbf;
}
body .ui-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}
body .ui-button:enabled:active {
  background-color: #005b9f;
  color: #fff;
  border-color: #005b9f;
}
body .ui-button.ui-button-text-only .ui-button-text {
  padding: 0.429em 1em;
}
body .ui-button.ui-button-text-icon-left .ui-button-text {
  padding: 0.429em 1em 0.429em 2em;
}
body .ui-button.ui-button-text-icon-right .ui-button-text {
  padding: 0.429em 2em 0.429em 1em;
}
body .ui-button.ui-button-icon-only {
  width: 2.357em;
}
body .ui-button.ui-button-icon-only .ui-button-text {
  padding: 0.429em;
}
body .ui-button.ui-button-raised {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}
body .ui-button.ui-button-rounded {
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
body .ui-fluid .ui-button-icon-only {
  width: 2.357em;
}
body .ui-togglebutton {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-togglebutton .ui-button-icon-left {
  color: #666666;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #333333;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
  color: #212121;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #333333;
  outline: 0 none;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus .ui-button-icon-left {
  color: #212121;
}
body .ui-togglebutton.ui-state-active {
  background-color: #007ad9;
  border-color: #007ad9;
  color: #fff;
}
body .ui-togglebutton.ui-state-active .ui-button-icon-left {
  color: #fff;
}
body .ui-togglebutton.ui-state-active:hover {
  background-color: #116fbf;
  border-color: #116fbf;
  color: #fff;
}
body .ui-togglebutton.ui-state-active:hover .ui-button-icon-left {
  color: #fff;
}
body .ui-togglebutton.ui-state-active.ui-state-focus {
  background-color: #005b9f;
  border-color: #005b9f;
  color: #fff;
}
body .ui-togglebutton.ui-state-active.ui-state-focus .ui-button-icon-left {
  color: #fff;
}
body .ui-selectbutton .ui-button {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-selectbutton .ui-button .ui-button-icon-left {
  color: #666666;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #333333;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
  color: #212121;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #333333;
  outline: 0 none;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus .ui-button-icon-left {
  color: #212121;
}
body .ui-selectbutton .ui-button.ui-state-active {
  background-color: #007ad9;
  border-color: #007ad9;
  color: #fff;
}
body .ui-selectbutton .ui-button.ui-state-active .ui-button-icon-left {
  color: #fff;
}
body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover {
  background-color: #116fbf;
  border-color: #116fbf;
  color: #fff;
}
body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-button-icon-left {
  color: #fff;
}
body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus {
  background-color: #005b9f;
  border-color: #005b9f;
  color: #fff;
}
body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus .ui-button-icon-left {
  color: #fff;
}
body .ui-selectbutton .ui-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-selectbutton .ui-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
@media (max-width: 640px) {
  body .ui-buttonset:not(.ui-splitbutton) .ui-button {
    margin-bottom: 1px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
}
body .ui-splitbutton.ui-buttonset .ui-button {
  border: 1px solid transparent;
}
body .ui-splitbutton.ui-buttonset .ui-menu {
  min-width: 100%;
}
body .ui-button.ui-state-default.ui-button-secondary, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default {
  color: #333333;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:hover, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:hover {
  background-color: #c8c8c8;
  color: #333333;
  border-color: #c8c8c8;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:active, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:active {
  background-color: #a0a0a0;
  color: #333333;
  border-color: #a0a0a0;
}
body .ui-button.ui-state-default.ui-button-info, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default {
  color: #fff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
}
body .ui-button.ui-state-default.ui-button-info:enabled:hover, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:hover {
  background-color: #116fbf;
  color: #fff;
  border-color: #116fbf;
}
body .ui-button.ui-state-default.ui-button-info:enabled:focus, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}
body .ui-button.ui-state-default.ui-button-info:enabled:active, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:active {
  background-color: #005b9f;
  color: #fff;
  border-color: #005b9f;
}
update > div > p-toastitem > div > div > div.install-button-container.ng-star-inserted > div:nth-child(2) > button,install > div > p-toastitem > div > div > div.install-button-container.ng-star-inserted > div:nth-child(2) > button{
  color: #fff;
  background-color: #34A835;
  border: 1px solid #34A835;
}
body .ui-button.ui-state-default.ui-button-success, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default {
  color: #fff;
  background-color: #34A835;
  border: 1px solid #34A835;
}
body .ui-button.ui-state-default.ui-button-success:enabled:hover, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:hover {
  background-color: #107D11;
  color: #fff;
  border-color: #107D11;
}
body .ui-button.ui-state-default.ui-button-success:enabled:focus, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #aae5aa;
  -moz-box-shadow: 0 0 0 0.2em #aae5aa;
  box-shadow: 0 0 0 0.2em #aae5aa;
}
body .ui-button.ui-state-default.ui-button-success:enabled:active, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:active {
  background-color: #0C6B0D;
  color: #fff;
  border-color: #0C6B0D;
}
body .ui-button.ui-state-default.ui-button-warning, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default {
  color: #333333;
  background-color: #ffba01;
  border: 1px solid #ffba01;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:hover, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:hover {
  background-color: #ED990B;
  color: #333333;
  border-color: #ED990B;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:focus, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #ffeab4;
  -moz-box-shadow: 0 0 0 0.2em #ffeab4;
  box-shadow: 0 0 0 0.2em #ffeab4;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:active, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:active {
  background-color: #D38B10;
  color: #333333;
  border-color: #D38B10;
}
body .ui-button.ui-state-default.ui-button-danger, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default {
  color: #fff;
  background-color: #e91224;
  border: 1px solid #e91224;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:hover, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:hover {
  background-color: #c01120;
  color: #fff;
  border-color: #c01120;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:focus, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #f9b4ba;
  -moz-box-shadow: 0 0 0 0.2em #f9b4ba;
  box-shadow: 0 0 0 0.2em #f9b4ba;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:active, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:active {
  background-color: #a90000;
  color: #fff;
  border-color: #a90000;
}

body .ui-widget-content p {
  line-height: 1.5;
  margin: 0;
}
body .ui-panel {
  padding: 0;
  border: 0 none;
}
body .ui-panel .ui-panel-titlebar {
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  background-color: #f4f4f4;
  color: #333333;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
body .ui-panel .ui-panel-titlebar .ui-panel-title {
  vertical-align: middle;
  font-weight: 700;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
  margin: 0;
  position: relative;
  font-size: 14px;
  color: #848484;
  border: 1px solid transparent;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  color: #333333;
}
body .ui-panel .ui-panel-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-panel .ui-panel-footer {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  border-top: 0 none;
  margin: 0;
}
body .ui-fieldset {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
}
body .ui-fieldset .ui-fieldset-legend a {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #f4f4f4;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-toggler {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  color: #848484;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-legend-text {
  padding: 0;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  color: #333333;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover .ui-fieldset-toggler {
  color: #333333;
}
body .ui-fieldset .ui-fieldset-content {
  padding: 0;
}
body .ui-accordion .ui-accordion-header {
  margin-bottom: 2px;
}
body .ui-accordion .ui-accordion-header a {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #f4f4f4;
  color: #333333;
  font-weight: 700;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon {
  color: #848484;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  color: #333333;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-accordion-toggle-icon {
  color: #333333;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  background-color: #007ad9;
  border: 1px solid #007ad9;
  color: #fff;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon {
  color: #fff;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a {
  border: 1px solid #005b9f;
  background-color: #005b9f;
  color: #fff;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a .ui-accordion-toggle-icon {
  color: #fff;
}
body .ui-accordion .ui-accordion-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tabview.ui-tabview-top, body .ui-tabview.ui-tabview-bottom, body .ui-tabview.ui-tabview-left, body .ui-tabview.ui-tabview-right {
  border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav, body .ui-tabview.ui-tabview-left .ui-tabview-nav, body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  padding: 0;
  background: transparent;
  border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a {
  float: none;
  display: inline-block;
  color: #333333;
  padding: 0.571em 1em;
  font-weight: 700;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-left-icon {
  margin-right: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-right-icon {
  margin-left: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close {
  color: #848484;
  margin: 0 0.5em 0 0;
  vertical-align: middle;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: #007ad9;
  border: 1px solid #007ad9;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: #fff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active .ui-tabview-close {
  color: #fff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
  border: 1px solid #005b9f;
  background-color: #005b9f;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a {
  color: #fff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon {
  color: #fff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-selected a {
  cursor: pointer;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav {
  margin-bottom: -1px;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  margin-right: 2px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav {
  margin-top: -1px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li {
  margin-right: 2px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav {
  margin-right: -px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li {
  margin-bottom: 2px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  margin-right: -1px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  margin-bottom: 2px;
}
body .ui-tabview .ui-tabview-panels {
  background-color: #fff;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
}
body .ui-tabview .ui-tabview-panels .ui-tabview-panel {
  padding: 0;
}
body .ui-toolbar {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}
body .ui-toolbar button {
  vertical-align: middle;
}
body .ui-toolbar .ui-toolbar-separator {
  vertical-align: middle;
  color: #848484;
  margin: 0 0.5em;
}
body .ui-card {
  background-color: #fff;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

body .ui-paginator {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0;
}
body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-first:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-prev:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-next:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-last:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-paginator .ui-paginator-current {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  line-height: 2.286em;
}
body .ui-paginator .ui-dropdown {
  border: 0 none;
}
body .ui-paginator .ui-dropdown .ui-dropdown-trigger, body .ui-paginator .ui-dropdown .ui-dropdown-label {
  color: #848484;
}
body .ui-paginator .ui-dropdown:hover .ui-dropdown-trigger, body .ui-paginator .ui-dropdown:hover .ui-dropdown-label {
  color: #333333;
}
body .ui-paginator .ui-paginator-first:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-prev:before {
  position: relative;
}
body .ui-paginator .ui-paginator-next:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-last:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-pages {
  vertical-align: top;
  display: inline-block;
  padding: 0;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #007ad9;
  color: #fff;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-paginator .ui-dropdown {
  margin-left: 0.5em;
  height: 2.286em;
  min-width: auto;
}
body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center;
}
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  font-weight: 700;
}
body .ui-table .ui-table-summary {
  border-top: 0 none;
  font-weight: 700;
}
body .ui-table .ui-table-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4;
}
body .ui-table .ui-table-tbody > tr > td {
  padding: 0.571em 0.857em;
}
body .ui-table .ui-table-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #fff;
}
body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #848484;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #333333;
}
body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #fff;
}
body .ui-table .ui-editable-column input {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}
body .ui-table .ui-editable-column input:focus {
  outline: 1px solid #007ad9;
  outline-offset: 2px;
}
body .ui-table .ui-table-tbody > tr {
  background-color: #fff;
  color: #333333;
}
body .ui-table .ui-table-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight a {
  color: #fff;
}
body .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
  background-color: #007ad9;
  color: #fff;
}
body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
  -webkit-box-shadow: inset 0 -2px 0 0 #007ad9;
  -moz-box-shadow: inset 0 -2px 0 0 #007ad9;
  box-shadow: inset 0 -2px 0 0 #007ad9;
}
body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight a {
  color: #fff;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-contextmenu-selected {
  background-color: #007ad9;
  color: #fff;
}
body .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: #333333;
}
body .ui-table .ui-column-resizer-helper {
  background-color: #007ad9;
}
/* @media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-paginator-top {
    border-bottom: 1px solid #c8c8c8;
  }
  body .ui-table.ui-table-responsive .ui-paginator-bottom {
    border-top: 1px solid #c8c8c8;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
    border: 0 none;
  }
} */
body .ui-datagrid .ui-datagrid-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-datagrid .ui-datagrid-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datagrid .ui-datagrid-footer {
  background-color: #fff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-datalist .ui-datalist-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-datalist .ui-datalist-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datalist .ui-datalist-footer {
  background-color: #fff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-datascroller .ui-datascroller-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-datascroller .ui-datascroller-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datascroller .ui-datascroller-footer {
  background-color: #fff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-virtualscroller .ui-virtualscroller-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-virtualscroller .ui-virtualscroller-content {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
}
body .ui-virtualscroller .ui-virtualscroller-content .ui-virtualscroller-list li {
  border-bottom: 1px solid #c8c8c8;
}
body .ui-virtualscroller .ui-virtualscroller-footer {
  background-color: #fff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-dataview .ui-dataview-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-dataview .ui-dataview-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-dataview .ui-dataview-footer {
  background-color: #fff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .fc th {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  color: #333333;
  padding: 0.571em 1em;
}
body .fc td.ui-widget-content {
  background-color: #fff;
  border: 1px solid #c8c8c8;
  color: #333333;
}
body .fc td.fc-head-container {
  border: 1px solid #c8c8c8;
}
body .fc .fc-row {
  border-right: 1px solid #c8c8c8;
}
body .fc .fc-event {
  background-color: #116fbf;
  border: 1px solid #116fbf;
  color: #fff;
}
body .fc .fc-toolbar .fc-button {
  color: #fff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .fc .fc-toolbar .fc-button:enabled:hover {
  background-color: #116fbf;
  color: #fff;
  border-color: #116fbf;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}
body .fc .fc-toolbar .fc-button-group .fc-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .fc .fc-toolbar .fc-button-group .fc-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .fc .fc-toolbar .fc-button-group .fc-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .fc .fc-divider {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
}
body .ui-fluid .fc .fc-toolbar .fc-button {
  width: auto;
}
/* body .ui-picklist .ui-picklist-buttons button {
  font-size: 16px;
}
body .ui-picklist .ui-picklist-caption {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-picklist .ui-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #fff;
  margin: 0;
  border-bottom: 0 none;
}
body .ui-picklist .ui-picklist-filter-container input.ui-picklist-filter {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}
body .ui-picklist .ui-picklist-filter-container .ui-picklist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #007ad9;
}
body .ui-picklist .ui-picklist-buttons {
  padding: 0.571em 1em;
}
body .ui-picklist .ui-picklist-list {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-picklist .ui-picklist-list .ui-picklist-droppoint-highlight {
  background-color: #007ad9;
} */
/* @media (max-width: 40em) {
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
    padding: 0.571em 1em;
  }
} */
body .ui-orderlist .ui-orderlist-controls {
  padding: 0.571em 1em;
}
body .ui-orderlist .ui-orderlist-controls button {
  font-size: 16px;
}
body .ui-orderlist .ui-orderlist-caption {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-orderlist .ui-orderlist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #fff;
  margin: 0;
  border-bottom: 0 none;
}
body .ui-orderlist .ui-orderlist-filter-container input.ui-inputtext {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}
body .ui-orderlist .ui-orderlist-filter-container .ui-orderlist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #007ad9;
}
body .ui-orderlist .ui-orderlist-list {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-droppoint-highlight {
  background-color: #007ad9;
}
body .ui-tree {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tree .ui-tree-container {
  padding: 0.286em;
  margin: 0;
}
body .ui-tree .ui-tree-container .ui-treenode {
  padding: 0.143em 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
  padding: 0;
  border: 1px solid transparent;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
  vertical-align: middle;
  display: inline-block;
  float: none;
  margin: 0 0.143em 0 0;
  color: #848484;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0.143em 0 0;
  color: #848484;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  padding: 0.286em;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
  margin: 0 0.5em 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
  margin: 1px 0 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content > span {
  line-height: inherit;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-dragover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-tree .ui-tree-container .ui-treenode-droppoint.ui-treenode-droppoint-active {
  background-color: #007ad9;
}
body .ui-tree.ui-tree-horizontal {
  padding-left: 0;
  padding-right: 0;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon {
  color: #007ad9;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: inherit;
  color: inherit;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-tree .ui-tree-filter-container .ui-tree-filter {
  width: 100%;
  padding-right: 2em;
}
body .ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.8em;
  color: #007ad9;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight .ui-node-toggler i {
  color: #00325a;
}
body .ui-organizationchart .ui-organizationchart-line-down {
  background-color: #c8c8c8;
}
body .ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid #c8c8c8;
  border-color: #c8c8c8;
}
body .ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid #c8c8c8;
  border-color: #c8c8c8;
}
body .ui-organizationchart .ui-organizationchart-node-content {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  bottom: -0.7em;
  margin-left: -0.46em;
  color: #848484;
}
body .ui-carousel {
  padding: 0;
}
body .ui-carousel .ui-carousel-header {
  background-color: #f4f4f4;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  margin: 0;
  font-weight: 700;
}
body .ui-carousel .ui-carousel-header .ui-carousel-header-title {
  padding: 0;
}
body .ui-carousel .ui-carousel-viewport {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0;
}
body .ui-carousel .ui-carousel-viewport .ui-carousel-items .ui-carousel-item {
  border: 1px solid #c8c8c8;
}
body .ui-carousel .ui-carousel-footer {
  background-color: #f4f4f4;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  margin: 0;
}
body .ui-carousel .ui-carousel-button {
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-carousel .ui-carousel-button.ui-carousel-next-button:before {
  content: "";
}
body .ui-carousel .ui-carousel-button.ui-carousel-prev-button:before {
  content: "";
}
body .ui-carousel .ui-carousel-button:not(.ui-state-disabled):hover {
  color: #333333;
}
body .ui-carousel .ui-carousel-page-links {
  margin: 0.125em 0.5em;
}
body .ui-carousel .ui-carousel-page-links .ui-carousel-page-link {
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-carousel .ui-carousel-page-links .ui-carousel-page-link:hover {
  color: #333333;
}


body .ui-carousel .ui-carousel-content .ui-carousel-prev,
body .ui-carousel .ui-carousel-content .ui-carousel-next {
  background-color: #fff;
  border: solid 1px rgba(178, 193, 205, 0.64);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0.2em;
  color: #333333;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-carousel .ui-carousel-content .ui-carousel-prev:not(.ui-state-disabled):hover,
body .ui-carousel .ui-carousel-content .ui-carousel-next:not(.ui-state-disabled):hover {
  background-color: #fff;
  color: #007ad9;
  border-color: solid 1px rgba(178, 193, 205, 0.64);
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item > .ui-button {
  border-color: transparent;
  background-color: transparent;
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item .ui-carousel-dot-icon {
  width: 20px;
  height: 6px;
  background-color: #b2c1cd;
  margin: 0 0.2em;
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item .ui-carousel-dot-icon::before {
  content: " ";
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item.ui-state-highlight .ui-carousel-dot-icon {
  background-color: #007ad9;
}






body .ui-treetable .ui-treetable-caption,
body .ui-treetable .ui-treetable-summary {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center;
}
body .ui-treetable .ui-treetable-caption {
  border-bottom: 0 none;
  font-weight: 700;
}
body .ui-treetable .ui-treetable-summary {
  border-top: 0 none;
  font-weight: 700;
}
body .ui-treetable .ui-treetable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4;
}
body .ui-treetable .ui-treetable-tbody > tr > td {
  padding: 0.571em 0.857em;
}
body .ui-treetable .ui-treetable-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #fff;
}
body .ui-treetable .ui-sortable-column .ui-sortable-column-icon {
  color: #848484;
}
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #333333;
}
body .ui-treetable .ui-sortable-column.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-treetable .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #fff;
}
body .ui-treetable .ui-editable-column input {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}
body .ui-treetable .ui-editable-column input:focus {
  outline: 1px solid #007ad9;
  outline-offset: 2px;
}
body .ui-treetable .ui-treetable-tbody > tr {
  background-color: #fff;
  color: #333333;
}
body .ui-treetable .ui-treetable-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}
body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-toggler {
  color: #848484;
  vertical-align: middle;
}
body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-chkbox {
  vertical-align: middle;
  margin-right: 0.5em;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight {
  background-color: #007ad9;
  color: #fff;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td .ui-treetable-toggler {
  color: #fff;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
  background-color: #007ad9;
  color: #fff;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected > td .ui-treetable-toggler {
  color: #fff;
}
body .ui-treetable.ui-treetable-hoverable-rows .ui-treetable-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: #333333;
}
body .ui-treetable .ui-column-resizer-helper {
  background-color: #007ad9;
}

body .ui-messages {
  padding: 1em;
  margin: 1em 0;
}
body .ui-messages.ui-messages-info {
  background-color: #7fbcec;
  border: 0 none;
  color: #212121;
}
body .ui-messages.ui-messages-info .ui-messages-icon {
  color: #212121;
}
body .ui-messages.ui-messages-info .ui-messages-close {
  color: #212121;
}
body .ui-messages.ui-messages-success {
  background-color: #b7d8b7;
  border: 0 none;
  color: #212121;
}
body .ui-messages.ui-messages-success .ui-messages-icon {
  color: #212121;
}
body .ui-messages.ui-messages-success .ui-messages-close {
  color: #212121;
}
body .ui-messages.ui-messages-warn {
  background-color: #ffe399;
  border: 0 none;
  color: #212121;
}
body .ui-messages.ui-messages-warn .ui-messages-icon {
  color: #212121;
}
body .ui-messages.ui-messages-warn .ui-messages-close {
  color: #212121;
}
body .ui-messages.ui-messages-error {
  background-color: #f8b7bd;
  border: 0 none;
  color: #212121;
}
body .ui-messages.ui-messages-error .ui-messages-icon {
  color: #212121;
}
body .ui-messages.ui-messages-error .ui-messages-close {
  color: #212121;
}
body .ui-messages .ui-messages-close {
  top: 0.25em;
  right: 0.5em;
  font-size: 1.5em;
}
body .ui-messages .ui-messages-icon {
  font-size: 2em;
}
body .ui-message {
  padding: 0.429em;
  margin: 0;
}
body .ui-message.ui-message-info {
  background-color: #7fbcec;
  border: 0 none;
  color: #212121;
}
body .ui-message.ui-message-info .ui-message-icon {
  color: #212121;
}
body .ui-message.ui-message-success {
  background-color: #b7d8b7;
  border: 0 none;
  color: #212121;
}
body .ui-message.ui-message-success .ui-message-icon {
  color: #212121;
}
body .ui-message.ui-message-warn {
  background-color: #ffe399;
  border: 0 none;
  color: #212121;
}
body .ui-message.ui-message-warn .ui-message-icon {
  color: #212121;
}
body .ui-message.ui-message-error {
  background-color: #f8b7bd;
  border: 0 none;
  color: #212121;
}
body .ui-message.ui-message-error .ui-message-icon {
  color: #212121;
}
body .ui-message .ui-message-icon {
  font-size: 1.25em;
}
body .ui-message .ui-message-text {
  font-size: 1em;
}
body .ui-growl {
  top: 70px;
}
body .ui-growl .ui-growl-item-container {
  margin: 0 0 1em 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-growl .ui-growl-item-container .ui-growl-item {
  padding: 1em;
}
body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-message {
  margin: 0 0 0 4em;
}
body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-image {
  font-size: 2.571em;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-info {
  background-color: #7fbcec;
  border: 0 none;
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-info .ui-growl-image {
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-success {
  background-color: #b7d8b7;
  border: 0 none;
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-success .ui-growl-image {
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-warn {
  background-color: #ffe399;
  border: 0 none;
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-warn .ui-growl-image {
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-error {
  background-color: #f8b7bd;
  border: 0 none;
  color: #212121;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-error .ui-growl-image {
  color: #212121;
}
body .ui-toast .ui-toast-message {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 1em 0;
}
body .ui-toast .ui-toast-message.ui-toast-message-info {
  background-color: #7fbcec;
  border: 0 none;
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-info .ui-toast-close-icon {
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-success {
  background-color: #b7d8b7;
  border: 0 none;
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-success .ui-toast-close-icon {
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn {
  background-color: #ffe399;
  border: 0 none;
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn .ui-toast-close-icon {
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-error {
  background-color: #f8b7bd;
  border: 0 none;
  color: #212121;
}
body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-close-icon {
  color: #212121;
}

body .ui-widget-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
body .ui-overlaypanel {
  background-color: #fff;
  color: #333333;
  padding: 0;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-overlaypanel .ui-overlaypanel-content {
  padding: 0.571em 1em;
}
body .ui-overlaypanel .ui-overlaypanel-close {
  background-color: #007ad9;
  color: #fff;
  width: 1.538em;
  height: 1.538em;
  line-height: 1.538em;
  text-align: center;
  position: absolute;
  top: -0.769em;
  right: -0.769em;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-overlaypanel .ui-overlaypanel-close:hover {
  background-color: #005b9f;
  color: #fff;
}
body .ui-overlaypanel .ui-overlaypanel-close .ui-overlaypanel-close-icon {
  line-height: inherit;
}
body .ui-overlaypanel:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
}
body .ui-overlaypanel:before {
  border-color: rgba(200, 200, 200, 0);
  border-bottom-color: #c8c8c8;
}
body .ui-overlaypanel.ui-overlaypanel-flipped:after {
  border-top-color: #fff;
}
body .ui-overlaypanel.ui-overlaypanel-flipped:before {
  border-top-color: #c8c8c8;
}
body .ui-dialog {
  padding: 0;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-dialog .ui-dialog-titlebar {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: #333333;
  padding: 1em;
  font-weight: 700;
  border-bottom: 0 none;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  margin: 0;
  float: none;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: #848484;
  border: 0 none;
  padding: 0;
  margin-left: 0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
  color: #333333;
}
body .ui-dialog .ui-dialog-content {
  background-color: #fff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}
body .ui-dialog .ui-dialog-footer {
  border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
  margin: 0;
  text-align: right;
  position: relative;
  top: -1px;
}
body .ui-dialog .ui-dialog-footer button {
  margin: 0 0.5em 0 0;
  width: auto;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content {
  padding: 1.5em;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span {
  float: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 14px;
  margin: 0;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span.ui-icon {
  margin-right: 0.35em;
  font-size: 16px;
}
body .ui-sidebar {
  background-color: #fff;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-sidebar .ui-sidebar-close {
  color: #848484;
}
body .ui-sidebar .ui-sidebar-close:hover {
  color: #333333;
}
body .ui-tooltip .ui-tooltip-text {
  background-color: #333333;
  color: #fff;
  padding: 0.429em;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
  border-right-color: #333333;
}
body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
  border-left-color: #333333;
}
body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
  border-top-color: #333333;
}
body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #333333;
}
body .ui-lightbox {
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-lightbox .ui-lightbox-caption {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: #333333;
  padding: 0.571em 1em;
  font-weight: 700;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
  color: #333333;
  margin: 0;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
  padding: 0;
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
  color: #333333;
}
body .ui-lightbox .ui-lightbox-content-wrapper {
  overflow: hidden;
  background-color: #fff;
  color: #333333;
  border: 0 none;
  padding: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 3em;
  color: #fff;
  margin-top: -0.5em;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:hover, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content.ui-lightbox-loading ~ a {
  display: none;
}

body .ui-breadcrumb {
  background-color: #fff;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}
body .ui-breadcrumb ul li .ui-menuitem-link {
  color: #333333;
  margin: 0;
}
body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
  margin: 0 0.5em 0 0.5em;
  color: #848484;
}
body .ui-breadcrumb ul li:first-child a {
  color: #848484;
  margin: 0;
}
body .ui-breadcrumb ul li .ui-menuitem-icon {
  color: #848484;
}
body .ui-steps {
  position: relative;
}
body .ui-steps .ui-steps-item {
  background-color: transparent;
  text-align: center;
}
body .ui-steps .ui-steps-item .ui-menuitem-link {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  color: #333333;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  position: relative;
  top: 16px;
  margin-bottom: 14px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  display: block;
  margin-top: 6px;
  color: #848484;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
  background: #007ad9;
  color: #fff;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
  font-weight: 700;
  color: #333333;
}
body .ui-steps .ui-steps-item:last-child .ui-menuitem-link {
  display: block;
}
body .ui-steps:before {
  content: " ";
  border-top: 1px solid #c8c8c8;
  width: 100%;
  top: 45%;
  left: 0;
  display: block;
  position: absolute;
}
body .ui-menu .ui-menuitem-link,
body .ui-menubar .ui-menuitem-link,
body .ui-tieredmenu .ui-menuitem-link,
body .ui-contextmenu .ui-menuitem-link,
body .ui-megamenu .ui-menuitem-link,
body .ui-slidemenu .ui-menuitem-link {
  padding: 0.714em 0.857em;
  color: #333333;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-menu .ui-menuitem-link .ui-menuitem-text,
body .ui-menubar .ui-menuitem-link .ui-menuitem-text,
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-text,
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-text,
body .ui-megamenu .ui-menuitem-link .ui-menuitem-text,
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-menu .ui-menuitem-link .ui-menuitem-icon,
body .ui-menubar .ui-menuitem-link .ui-menuitem-icon,
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-megamenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
  margin-right: 0.5em;
}
body .ui-menu .ui-menuitem-link:hover,
body .ui-menubar .ui-menuitem-link:hover,
body .ui-tieredmenu .ui-menuitem-link:hover,
body .ui-contextmenu .ui-menuitem-link:hover,
body .ui-megamenu .ui-menuitem-link:hover,
body .ui-slidemenu .ui-menuitem-link:hover {
  background-color: #eaeaea;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #333333;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #333333;
}
body .ui-menu {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
}
body .ui-menu .ui-menuitem {
  margin: 0;
}
body .ui-menu.ui-shadow {
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-menu .ui-submenu-header {
  margin: 0;
  padding: 0.714em 0.857em;
  color: #333333;
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}
body .ui-menu .ui-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}
body .ui-menubar {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
}
body .ui-menubar .ui-menubar-root-list > .ui-menuitem > .ui-menuitem-link {
  padding: 0.714em 0.857em;
}
body .ui-menubar .ui-menubar-root-list > .ui-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 0 0 0 1px;
}
body .ui-menubar .ui-submenu-list {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-menubar .ui-submenu-list .ui-menuitem {
  margin: 0;
}
body .ui-menubar .ui-submenu-list .ui-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}
body .ui-menubar .ui-menuitem {
  margin: 0;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #333333;
}
body .ui-contextmenu {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-contextmenu .ui-submenu-list {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-contextmenu .ui-menuitem {
  margin: 0;
}
body .ui-contextmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #333333;
}
body .ui-contextmenu .ui-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}
body .ui-tieredmenu {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
}
body .ui-tieredmenu .ui-submenu-list {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-tieredmenu .ui-menuitem {
  margin: 0;
}
body .ui-tieredmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #333333;
}
body .ui-tieredmenu .ui-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}
body .ui-slidemenu {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
}
body .ui-slidemenu .ui-submenu-list {
  padding: 0;
  background-color: #fff;
  border: 0 none;
}
body .ui-slidemenu .ui-menuitem {
  margin: 0;
}
body .ui-slidemenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #333333;
}
body .ui-slidemenu .ui-slidemenu-backward {
  margin: 0;
  padding: 0.571em 1em;
  color: #333333;
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}
body .ui-slidemenu .ui-slidemenu-backward:hover {
  background-color: #dbdbdb;
  color: #333333;
}
body .ui-slidemenu .ui-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}
body .ui-tabmenu {
  border: 0 none;
}
body .ui-tabmenu .ui-tabmenu-nav {
  padding: 0;
  background: transparent;
  border-bottom: 1px solid #c8c8c8;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
  position: static;
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  margin-right: 2px;
  margin-bottom: -1px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link {
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
  margin-right: 0.5em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-icon {
  color: #848484;
  margin-right: 0.5em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
  background-color: #007ad9;
  border: 1px solid #007ad9;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-text {
  color: #fff;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-icon {
  color: #fff;
}
body .ui-megamenu {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
}
body .ui-megamenu .ui-megamenu-submenu-header {
  margin: 0;
  padding: 0.571em 1em;
  color: #333333;
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}
body .ui-megamenu .ui-megamenu-panel {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-megamenu .ui-menuitem {
  margin: 0;
}
body .ui-megamenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #eaeaea;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #333333;
}
body .ui-megamenu.ui-megamenu-vertical .ui-megamenu-root-list > .ui-menuitem > .ui-menuitem-link > .ui-submenu-icon {
  right: 0.429em;
}
body .ui-panelmenu .ui-icon {
  position: static;
}
body .ui-panelmenu .ui-panelmenu-header {
  padding: 0;
}
body .ui-panelmenu .ui-panelmenu-header > a {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: #333333;
  padding: 0.714em 0.857em;
  font-weight: 700;
  position: static;
  font-size: 14px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  color: #848484;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover {
  outline: 0 none;
  border: 1px solid #dbdbdb;
  background-color: #dbdbdb;
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover .ui-panelmenu-icon {
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
  border: 1px solid #007ad9;
  background-color: #007ad9;
  color: #fff;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a .ui-panelmenu-icon {
  color: #fff;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover {
  outline: 0 none;
  border: 1px solid #005b9f;
  background-color: #005b9f;
  color: #fff;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover .ui-panelmenu-icon {
  color: #fff;
}
body .ui-panelmenu .ui-panelmenu-panel {
  margin-top: 2px;
}
body .ui-panelmenu .ui-panelmenu-panel:first-child {
  margin-top: 0;
}
body .ui-panelmenu .ui-panelmenu-content {
  padding: 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  margin-top: 0;
  position: static;
  border-top: 0 none;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem {
  margin: 0;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link {
  padding: 0.714em 0.857em;
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover {
  background-color: #eaeaea;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-icon, body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-panelmenu-icon {
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-text {
  color: #333333;
}

body .ui-progressbar {
  border: 0 none;
  height: 24px;
  background-color: #eaeaea;
}
body .ui-progressbar .ui-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #007ad9;
}
body .ui-progressbar .ui-progressbar-label {
  margin-top: 0;
  color: #333333;
  line-height: 24px;
}
body .ui-galleria .ui-galleria-nav-prev,
body .ui-galleria .ui-galleria-nav-next {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  color: #848484;
}
body .ui-galleria .ui-galleria-nav-prev:hover,
body .ui-galleria .ui-galleria-nav-next:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-galleria .ui-galleria-nav-prev {
  font-size: 16px;
}
body .ui-galleria .ui-galleria-nav-next {
  font-size: 16px;
}
body .ui-terminal {
  background-color: #fff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}
body .ui-terminal .ui-terminal-input {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  height: 16px;
}
body .ui-terminal .ui-terminal-command {
  height: 16px;
}
body .ui-inplace {
  min-height: 2.357em;
}
body .ui-inplace .ui-inplace-display {
  padding: 0.429em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-inplace .ui-inplace-display:not(.ui-state-disabled):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-fluid .ui-inplace.ui-inplace-closable .ui-inplace-content > :first-child {
  display: inline-block;
  width: calc(100% - 2.357em);
}

/* Add your customizations of theme here */

 /* Primng icons */
 @font-face {
  font-family: 'PrimeIcons';
  src: url('/assets/fonts/primeicons.eot');
  src: url('/assets/fonts/primeicons.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/primeicons.woff') format('woff'),
  url('/assets/fonts/primeicons.ttf') format('truetype'),
  url('/assets/fonts/primeicons.svg?#primeicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Primng icons */

.pi {
  font-family: 'primeicons';
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-fw {
  width: 1.28571429em;
  text-align: center;
}

.pi-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

.pi-mobile:before {
  content: "\e982";
}

.pi-tablet:before {
  content: "\e983";
}

.pi-key:before {
  content: "\e981";
}

.pi-shopping-cart:before {
  content: "\e980";
}

.pi-comments:before {
  content: "\e97e";
}

.pi-comment:before {
  content: "\e97f";
}

.pi-briefcase:before {
  content: "\e97d";
}

.pi-bell:before {
  content: "\e97c";
}

.pi-paperclip:before {
  content: "\e97b";
}

.pi-share-alt:before {
  content: "\e97a";
}

.pi-envelope:before {
  content: "\e979";
}

.pi-volume-down:before {
  content: "\e976";
}

.pi-volume-up:before {
  content: "\e977";
}

.pi-volume-off:before {
  content: "\e978";
}

.pi-eject:before {
  content: "\e975";
}

.pi-money-bill:before {
  content: "\e974";
}

.pi-images:before {
  content: "\e973";
}

.pi-image:before {
  content: "\e972";
}

.pi-sign-in:before {
  content: "\e970";
}

.pi-sign-out:before {
  content: "\e971";
}

.pi-wifi:before {
  content: "\e96f";
}

.pi-sitemap:before {
  content: "\e96e";
}

.pi-chart-bar:before {
  content: "\e96d";
}

.pi-camera:before {
  content: "\e96c";
}

.pi-lock-open:before {
  content: "\e96a";
}

.pi-table:before {
  content: "\e969";
}

.pi-map-marker:before {
  content: "\e968";
}

.pi-list:before {
  content: "\e967";
}

.pi-eye-slash:before {
  content: "\e965";
}

.pi-eye:before {
  content: "\e966";
}

.pi-folder-open:before {
  content: "\e964";
}

.pi-folder:before {
  content: "\e963";
}

.pi-video:before {
  content: "\e962";
}

.pi-inbox:before {
  content: "\e961";
}

.pi-lock:before {
  content: "\e95f";
}

.pi-unlock:before {
  content: "\e960";
}

.pi-tags:before {
  content: "\e95d";
}

.pi-tag:before {
  content: "\e95e";
}

.pi-power-off:before {
  content: "\e95c";
}

.pi-save:before {
  content: "\e95b";
}

.pi-question-circle:before {
  content: "\e959";
}

.pi-question:before {
  content: "\e95a";
}

.pi-copy:before {
  content: "\e957";
}

.pi-file:before {
  content: "\e958";
}

.pi-clone:before {
  content: "\e955";
}

.pi-calendar-times:before {
  content: "\e952";
}

.pi-calendar-minus:before {
  content: "\e953";
}

.pi-calendar-plus:before {
  content: "\e954";
}

.pi-ellipsis-v:before {
  content: "\e950";
}

.pi-ellipsis-h:before {
  content: "\e951";
}

.pi-bookmark:before {
  content: "\e94e";
}

.pi-globe:before {
  content: "\e94f";
}

.pi-replay:before {
  content: "\e94d";
}

.pi-filter:before {
  content: "\e94c";
}

.pi-print:before {
  content: "\e94b";
}

.pi-align-right:before {
  content: "\e946";
}

.pi-align-left:before {
  content: "\e947";
}

.pi-align-center:before {
  content: "\e948";
}

.pi-align-justify:before {
  content: "\e949";
}

.pi-cog:before {
  content: "\e94a";
}

.pi-cloud-download:before {
  content: "\e943";
}

.pi-cloud-upload:before {
  content: "\e944";
}

.pi-cloud:before {
  content: "\e945";
}

.pi-pencil:before {
  content: "\e942";
}

.pi-users:before {
  content: "\e941";
}

.pi-clock:before {
  content: "\e940";
}

.pi-user-minus:before {
  content: "\e93e";
}

.pi-user-plus:before {
  content: "\e93f";
}

.pi-trash:before {
  content: "\e93d";
}

.pi-external-link:before {
  content: "\e93c";
}

.pi-window-maximize:before {
  content: "\e93b";
}

.pi-window-minimize:before {
  content: "\e93a";
}

.pi-refresh:before {
  content: "\e938";
}

.pi-user:before {
  content: "\e939";
}

.pi-exclamation-triangle:before {
  content: "\e922";
}

.pi-calendar:before {
  content: "\e927";
}

.pi-chevron-circle-left:before {
  content: "\e928";
}

.pi-chevron-circle-down:before {
  content: "\e929";
}

.pi-chevron-circle-right:before {
  content: "\e92a";
}

.pi-chevron-circle-up:before {
  content: "\e92b";
}

.pi-angle-double-down:before {
  content: "\e92c";
}

.pi-angle-double-left:before {
  content: "\e92d";
}

.pi-angle-double-right:before {
  content: "\e92e";
}

.pi-angle-double-up:before {
  content: "\e92f";
}

.pi-angle-down:before {
  content: "\e930";
}

.pi-angle-left:before {
  content: "\e931";
}

.pi-angle-right:before {
  content: "\e932";
}

.pi-angle-up:before {
  content: "\e933";
}

.pi-upload:before {
  content: "\e934";
}

.pi-download:before {
  content: "\e956";
}

.pi-ban:before {
  content: "\e935";
}

.pi-star-o:before {
  content: "\e936";
}

.pi-star:before {
  content: "\e937";
}

.pi-chevron-left:before {
  content: "\e900";
}

.pi-chevron-right:before {
  content: "\e901";
}

.pi-chevron-down:before {
  content: "\e902";
}

.pi-chevron-up:before {
  content: "\e903";
}

.pi-caret-left:before {
  content: "\e904";
}

.pi-caret-right:before {
  content: "\e905";
}

.pi-caret-down:before {
  content: "\e906";
}

.pi-caret-up:before {
  content: "\e907";
}

.pi-search:before {
  content: "\e908";
}

.pi-check:before {
  content: "\e909";
}

.pi-check-circle:before {
  content: "\e90a";
}

.pi-times:before {
  content: "\e90b";
}

.pi-times-circle:before {
  content: "\e90c";
}

.pi-plus:before {
  content: "\e90d";
}

.pi-plus-circle:before {
  content: "\e90e";
}

.pi-minus:before {
  content: "\e90f";
}

.pi-minus-circle:before {
  content: "\e910";
}

.pi-circle-on:before {
  content: "\e911";
}

.pi-circle-off:before {
  content: "\e912";
}

.pi-sort-down:before {
  content: "\e913";
}

.pi-sort-up:before {
  content: "\e914";
}

.pi-sort:before {
  content: "\e915";
}

.pi-step-backward:before {
  content: "\e916";
}

.pi-step-forward:before {
  content: "\e917";
}

.pi-th-large:before {
  content: "\e918";
}

.pi-arrow-down:before {
  content: "\e919";
}

.pi-arrow-left:before {
  content: "\e91a";
}

.pi-arrow-right:before {
  content: "\e91b";
}

.pi-arrow-up:before {
  content: "\e91c";
}

.pi-bars:before {
  content: "\e91d";
}

.pi-arrow-circle-down:before {
  content: "\e91e";
}

.pi-arrow-circle-left:before {
  content: "\e91f";
}

.pi-arrow-circle-right:before {
  content: "\e920";
}

.pi-arrow-circle-up:before {
  content: "\e921";
}

.pi-info:before {
  content: "\e923";
}

.pi-info-circle:before {
  content: "\e924";
}

.pi-home:before {
  content: "\e925";
}

.pi-spinner:before {
  content: "\e926";
}

/* #mat-tab */

label {
  display: flex;
  margin-bottom: 3px
}

.mat-tab-label,.mat-tab-link {
  color: hsla(0,0%,100%,.9)
}

.mat-tab-label-active {
  font-weight: 700;
  opacity: 1!important;
  color: #fff
}


.title, p {
  display: block;

}
.title {
  font-size: 1.17em;
  font-weight: 700;
  background: #cdc6da;
  padding: 1em 0 .5em 1em
}
.textoverflow {
  max-height: 58px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
kruh-program .title {
  font-size: 1.2em;
  font-weight: 700;
  background: #cdc6da;
  padding: 0.5em 0 .5em 0;
  text-align: center;
}
kruh-program .next {
  font-size: 1.2em;
  font-weight: 700;
  background: #f49c36b8;
  padding: 0.5em;
  text-align: center;
}
body > p-dynamicdialog > div > div{
  max-width: 1280px;
}

/* Pwa custom css */

.ck-editor__editable{min-height:500px;}
/*BEGIN Toolbar */
.toolbar{ width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start}
app-toolbar,.ui-toast-message{color:#f9f9f9}
app-toolbar .ui-tieredmenu {
  width: 6.5em!important;
  padding: .25em
}
app-toolbar .ui-button {
  margin:0px;
  top: 0px;
  float: right;

  color: #fff;
  background-color: #bebebe00;
  border: 1px solid #bebebe00;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
app-toolbar .ui-button:enabled:hover {
  background-color: #acacac;
  color: #fff;
  border-color:  #acacac;
}
app-toolbar .ui-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.2em #cecece;
  -moz-box-shadow: 0 0 0 0.2em #cecece;;
  box-shadow: 0 0 0 0.2em #cecece;;
}
app-toolbar .ui-button:enabled:active {
  background-color: #005b9f;
  color: #fff;
  border-color: #005b9f;
}
app-toolbar button{
  text-shadow: 0 0 4px black;
}
app-toolbar > p-tieredmenu > div > p-tieredmenusub > ul{
  text-shadow:none;
  font-weight: 600;
}



.mat-toolbar.mat-accent {
  background: #ba2626!important;
  color: #000000de;
}

/* END Toolbar */
/* iphone 8 */
@media screen and (max-width:414px) and (orientation:portrait)
{

kruh-program > p-card >  div > div.p-card-body > div > div > ul > li  div > img:hover
{
  transform: scale(1.3) translate(-10px, 10px)!important;
}

#p-controller{
  top:437px!important
}
  .fk-synosis p{
    padding-left:0!important
  }
  .fk-synosis {

    padding-left:0!important
  }
  #programlist > ul {
  padding-left:0!important;
  list-style-type: none;
  }
  #programlist > ul li>div{
    max-width: unset!important;
  }
}

/* @media screen and (max-width:820px) and (orientation:portrait)
{

  #programlist > ul li>div{
    max-width: 97%;
  }
  kruh-program .regieblock {
    min-width: 83%!important;
  }
} */

@media screen and (max-width: 1024px) 
{
   .p-carousel-item {
    flex: 1 0 100%!important;
  }
}

/* @media screen and (max-width:414px) and (orientation:portrait), screen and (max-width:736px) and (orientation:landscape)
{
  .ui-megamenu-root-list{font-size:12px!important}
  .container {margin-top: 81px}
  h3{margin-left:5px}
  h6{margin-left:0px}
}

@media screen and (max-width:414px) and (orientation:portrait)
{
  .ui-lightbox-content img{max-width: 300px}
  .ui-lightbox{left:35px!important}
  .ui-lightbox-caption-text{font-size: 11px}
}
@media only screen
     and (device-width : 667px)
    and (max-device-height : 414px)
    and
    (orientation : landscape)   {
      .ui-lightbox-content img
      {
      max-height: 280px;

      }
    .ui-lightbox-caption-text{
        font-size: 10px;
      }
      .ui-lightbox
      {
        top:30px!important;
      }
} */
/* @media only screen
and (max-width:414px)
    and
    (orientation : landscape)   {
      .ui-lightbox-content img
      {
      max-height: 280px;

      }
    .ui-lightbox-caption-text{
        font-size: 10px;
      }
      .ui-lightbox
      {
        top:30px!important;
      }
} */
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    vertical-align: bottom;
  }
  html,
  body {
    margin: 0;
    width: 100%;
  height: 100%;
    /* height: 100%; */
  }
  body {
    background:transparent;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }

  body > div.hero > kruh-root > p-toast.ng-tns-c10-1 > div > p-toastitem > div > div > div.ui-g.ui-fluid.ng-star-inserted > div > button
  {
    background-color: #b3b3b3;
    font-size: 11px;
  }

  /*--- body borders ------------------------------------------------------------------------*/
  .hero{
    width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  z-index: -10;

  }
  .hero::before{
    background: url(/assets/img/bg/bg13.webp) no-repeat left top fixed;
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 1;
    z-index: -10;
  }
  @media all and (min-width:415px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
    .selector:not(*:root), body { font-size:100.01%;
      background:url(/assets/img/bg/bg13.webp) no-repeat left top fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .bg1::before{
      background: url(/assets/img/bg/bg1.webp) no-repeat left top fixed;
    }
    .bg2::before{
      background: url(/assets/img/bg/bg2.webp) no-repeat left top fixed;
    }
    .bg3::before{
      background: url(/assets/img/bg/bg3.webp) no-repeat left top fixed;
    }
    .bg4::before{
      background: url(/assets/img/bg/bg4.webp) no-repeat left top fixed;
    }
    .bg5::before{
      background: url(/assets/img/bg/bg5.webp) no-repeat left top fixed;
    }
    .bg6::before{
      background: url(/assets/img/bg/bg6.webp) no-repeat left top fixed;
    }
    .bg7::before{
      background: url(/assets/img/bg/bg7.webp) no-repeat left top fixed;
    }
    .bg8::before{
      background: url(/assets/img/bg/bg8.webp) no-repeat left top fixed;
    }
    .bg9::before{
      background: url(/assets/img/bg/bg9.webp) no-repeat left top fixed;
    }
    .bg10::before{
      background: url(/assets/img/bg/bg10.webp) no-repeat left top fixed;
    }
    .bg11::before{
      background: url(/assets/img/bg/bg11.webp) no-repeat left top fixed;
    }
    .bg12::before{
      background: url(/assets/img/bg/bg12.webp) no-repeat left top fixed;
    }
    .bg13::before{
      background: url(/assets/img/bg/bg13.webp) no-repeat left top fixed;
    }
  } 
  .bg1::before{
    background: url(/assets/img/bg/bg1.webp) no-repeat top fixed;
    background-size:100% 100%;
  }
  .bg2::before{
    background: url(/assets/img/bg/bg2.webp) no-repeat top fixed;
    background-size:100% 100%;
  }
  .bg3::before{
    background: url(/assets/img/bg/bg3.webp) no-repeat top fixed;
    background-size:100% 100%;
  }
  .bg4::before{
    background: url(/assets/img/bg/bg4.webp) no-repeat top fixed;
    background-size:100% 100%;
  }
  .bg5::before{
    background: url(/assets/img/bg/bg5.webp) no-repeat top fixed;
    background-size:100% 100%;
  }
  .bg6::before{
    background: url(/assets/img/bg/bg6.webp) no-repeat top fixed;
    background-size:100% 100%;
  }
  .bg7::before{
    background: url(/assets/img/bg/bg7.webp) no-repeat left top fixed;
    background-size:100% 100%;
  }
  .bg8::before{
    background: url(/assets/img/bg/bg8.webp) no-repeat left top fixed;
    background-size:100% 100%;
  }
  .bg9::before{
    background: url(/assets/img/bg/bg9.webp) no-repeat left top fixed;
    background-size:100% 100%;
  }
  .bg10::before{
    background: url(/assets/img/bg/bg10.webp) no-repeat left top fixed;
    background-size:100% 100%;
  }
  .bg11::before{
    background: url(/assets/img/bg/bg11.webp) no-repeat left top fixed;
    background-size:100% 100%;
  }
  .bg12::before{
    background: url(/assets/img/bg/bg12.webp) no-repeat left top fixed;
    background-size:100% 100%;
  }
 
  .bg13::before{
    background: url(/assets/img/bg/bg13.webp) no-repeat left top fixed;
    background-size:100% 100%;
  }
 
  .transparent {
      zoom: 1;
      filter: alpha(opacity=40);
      opacity: 0.4;
  }
  

  #btop, #bbottom, #bleft, #bright {background: #fff}
  #bleft, #bright {
      position: fixed;
      top: 0; bottom: 0;
      width: 15px;
      }
  #bleft{left:0}
  #bright{right:0}

    #btop, #bbottom {
      position: fixed;
      left: 0;
      right: 0;
      height: 15px;
      }
  #btop{top:0}
  #bbottom{bottom:0}


  @media
  /* Fairly small screens including iphones */
  only screen and (max-width: 500px),
  /* iPads */
  only screen and (min-device-width: 768px) and (max-device-width: 1024px)
  {
    #ontop, #btop, #bbottom, #bleft, #bright { display: none; }
  }
  .mat-drawer-container {
      /* position: relative; */
      /* z-index: 1; */
      /* box-sizing: border-box; */
      -webkit-overflow-scrolling: touch;
      /* display: block; */
      /* overflow: hidden; */
      height: 100vh;
      align-items: stretch;
      align-content: stretch;
      display: flex!important;
      flex-direction: column;
    background-color: transparent!important;
    color: rgba(0,0,0,.87);
}

.ui-toast-detail{
  white-space: pre-line;
  line-height: 3;
}
.ui-confirmdialog-message {
  white-space: pre-line;
}

.preview {

  overflow-y: auto;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
kruh-program,kruh-link,kruh-editorial,kruh-edition{
  background-color: white;
}
.preview >div{
  margin: 0 5px 5px 10px;
}

.filmdetailinforechts img {
  display: inline-block;
  width: 34px;
  height: auto
}

.filmdetailinforechts a {
  color: #000
}
.filmdetailinforechts > p{
  margin-left: 14px!important;
}


#filmdetails-share {
  margin-left: 44px
}

kruh-program-details-dialog .container-details {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  width: 94%;
  justify-content: space-between;
}

.filmdetailinforechts img,.filmdetailinforechts .ffk-icon {
  margin-right: 10px
}

.filmdetailinfolinks,.filmdetailinforechts {
  flex: 1 1 100%;
  margin-top: 20px;
}

.filmdetailinfolinks> div > p
{
  margin-bottom: 8px;
}
.fk-synosis p{
  line-height: 2!important;
  padding-bottom: 40px;
  padding-left: 10px;
  font-size: 16px;
}
.fk-synosis {
  line-height: 2!important;
  padding-bottom: 40px;
  padding-left: 10px;
  font-size: 16px;
}

.preview h1{
  text-transform: uppercase;
text-align: center
}

.preview > div.center{
  text-align: center
}
.italic{
  font-style: italic;
}

.filmdetailinforechts a {
  color: #000
}
.filmdetailinforechts img {
  display: inline-block;
  width: 34px;
  height: auto
}

.ffk-icon {
  background: url("/assets/img/icons.png");
  display: inline-block;
  vertical-align: middle
}
.ffk-icon-facebook {
  background-position: -10px -61px;
  width: 29px;
  height: 29px
}

.ffk-icon-twitter {
  background-position: -60px -61px;
  width: 29px;
  height: 29px
}

.ffk-icon-youtube {
  background-position: -110px -61px;
  width: 29px;
  height: 29px
}

.ffk-icon-instagram {
  background-position: -160px -61px;
  width: 29px;
}

.ffk-icon-share {
  background-position: -9px -155px;
  width: 34px;
  height: 34px
}

.ffk-icon-trailer {
  background-position: -58px -155px;
  width: 34px;
  height: 34px
}
.ffk-icon-google {
  background-position: -210px -61px;
  width: 29px;
  height: 29px
}

.ffk-icon-facebook-gross {
  background-position: -4px -104px;
  width: 42px;
  height: 42px
}

.ffk-icon-twitter-gross {
  background-position: -54px -104px;
  width: 42px;
  height: 42px
}

.ffk-icon-youtube-gross {
  background-position: -104px -104px;
  width: 42px;
  height: 42px
}

.ffk-icon-instagram-gross {
  background-position: -154px -104px;
  width: 42px;
  height: 42px
}

.ffk-icon-google-gross {
  background-position: -204px -104px;
  width: 42px;
  height: 42px
}
#filmdetails-share {
  margin-left: 44px
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

body .ui-accordion .ui-accordion-header a {
  padding: 0.571em 1em;
  border: 0px solid #c8c8c8;
  background-color: transparent!important;
  color: #333333;
  font-weight:inherit!important;
  transition: background-color 0.2s;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  background-color: transparent!important;
  border: 0px solid #007ad9;
  color: inherit!important;
}
body .ui-accordion .ui-accordion-content {
  padding: 0.571em 1em;
  border: 0px;
  background-color:transparent!important;
  color:inherit!important;
  padding: 0.571em 1em;
}
body .ui-accordion .ui-accordion-header a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1em #ab914c;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon {
  color: #ab914c!important;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a {
  border: 1px solid #ab914c;

}

.custom-carousel
{
  max-width: 920px;
}

.product-item
  .product-image {
      width: 85%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}
.product-item .product-item-content {

      border: 1px solid var(--surface-d);
      border-radius: 3px;
      margin: .0;
      text-align: center;
      padding:  0;

}
label.required::after{
  content:" *";
  color: red;
}
#mdetails input{
  width:100%;
  margin-right: .5em
}

.ytimg-background{

  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 50vh;
  margin: 3px auto 0;
  position: relative;

}
.manage-link .seperator {
  margin-left: 8px;
  margin-right: 8px;
}
a:not(.ms-Button):not(.is-link) {
  text-decoration: none;
}
.manage-link .pi-external-link {
  padding-left: 5px;
}
.displayinline
{
  display: inline;
}
.displayinlineblock,.programm-img,programm-img-2
{
  display: inline-block;
}

 .programm-img-2 > div> img{
  width: 120px;
  height:120px;
 }
 .programm-img > div> img{
  width: 100px;
  height:100px;
 }

.float-right
{
  float: right;
}

/* Program public */

/* Corresponds to fxFlex="88" */
.flex-88 {
  flex: 0 0 88%;
  max-width: 88%;
}
.flex-lt-sm-73 {
  flex: 0 0 98%;
  max-width: 98%;
}

/* Corresponds to fxFlex.lt-sm="75" */
@media (max-width: 599px) {
  .flex-lt-sm-73 {
      flex: 0 0 72%;
      max-width: 72%;
  }
  kruh-program .p-input-icon-left
  {
    display: inherit;
  }
  .filmdetailinfolinks{
    max-width: 100%;
  }
  kruh-program-details-dialog .container-details{
    flex-direction: column;
  }
}

/* Corresponds to fxLayout.gt-sm="row" */
@media (min-width: 960px) {
  .layout-gt-sm-row {
      display: flex;
      flex-direction: row;
  }
  .programm-img {
    display: none;
  }
}

/* Corresponds to fxLayout.lt-sm="column" */
@media (max-width: 599px) {
  .layout-lt-sm-column {
      display: flex;
      flex-direction: column;
  }
}




.width100{
  width: 100%;
}
.scrollable-content.content div P {
  line-height: 30px;
}
.filmdetailinfolinks>div>p>span ,div.filmdetailinforechts > div > p > span{
  color: #666666;
  font-size: smaller;
  display: inline-block;
  width: 15%;
}
.p-dialog-mask {
  z-index: 900!important;
}



kruh-program .cert-result li{
margin-top:15px;
}
kruh-program .cert-result h4{
margin-top: 0;
}
/* kruh-program .cert-result li >div{
  width:17%
} */

 kruh-program > p-card > div > div.p-card-body >  div > div > ul > li > div.width-100{
   width: 100%;
 }
 kruh-program .regie-img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

kruh-program .program-content-vh-80 {
  height: 80vh!important;
}
.ptitle{
  font-size: x-large;font-weight: 500;
}


.editorial-search,.video-search  {
  max-width: 88%;
}

kruh-editorial > p-card > div > div.p-card-body > div > form > div > div:nth-child(2) > span{
  max-width: 98%;
}
/* Layout.lt-sm */
@media screen and (max-width: 599px) {
  .editorial-search,.video-search {
max-width: 75%;
  }
}

/* Layout.gt-sm */
@media screen and (min-width: 600px) {
  .kruh-editorial-search,.video-search  {
      flex-direction: row;
  }
}

/* layout.sm*/
@media (min-width: 600px) and (max-width: 959px) {
  kruh-program .regieblock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: top;
    line-height: 32px;
    font-size: 18px!important;
  }
  kruh-program .padding-left-0{
    padding-left: 0;
    font-size: larger;
  }
  kruh-program .cert-result-100{
    width: 100%!important;
  }
  kruh-program .content-vh-75{
    height: 75vh!important;
  }

}
/* layout.xs*/
@media (max-width: 599px) {
  kruh-program .regieblock-xs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: top;
    line-height: 32px;
    font-size: 16px!important
  }
  .padding-left-0-xs{
    padding-left: 0;
    font-size: large;
  }
  kruh-program .cert-result-100{
    width: 100%!important;
  }
  kruh-program .content-vh-75{
    height: 75vh!important;
  }
  kruh-program .kind-small {
    font-weight: 500;
     background-color: #ffdebf!important;
     margin-bottom: 8px;
     border: #e26e2b;
     border-width: thick;
     border-style: dotted;
     font-size: larger!important;
   }
   .ptitle-small
  {
    font-size: larger!important;
  }
}


/* layout.gt-sm */
@media (min-width: 960px) {
  kruh-program .regieblock-gt-sm {
    line-height: 24px;
    font-size: 13px!important
  }
}


/* .programmdata
{
  font-size: 9px;

} */
.imgcontent
{
  width: 100px;
  height: 100px;
  display: block;

}
.imgcontent img
{
margin-bottom: 8px;

}

kruh-program > p-card >  div > div.p-card-body > div > div > ul > li  div > img:hover
{
  transform: scale(1.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;

}

.italic{
  font-style: italic;
}
.close-button{
  float: right;
}
.close-icon:hover {
  transform: rotate(180deg);
}.close-icon {
  transition: 1s ease-in-out;
}
.pointer{
  cursor: pointer;
}
.pointer div b{
  color:#81848b
}

.cert-result ul li div{
  width:15%

}
.cert-result ul li div h4{
  margin-top: 0;

}

app-photos .p-card{
  height:89vh
}

#mat-tab-content-0-5{
  overflow-y: unset;
}
.photos{
  overflow-y: unset;
}
kruh-program-details-dialog  .p-carousel {
    margin: 0 auto;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.5rem;
}
 p-dynamicdialog > div > div > div.p-dialog-header > span{
   margin: 0 auto;
 }

.install-button{

  width: 20%;
  margin: 0 auto;
}
.install-button-container{
  width: 100%;
  display: flex;
}
#update .p-toast,#install .p-toast {
  position: fixed;
  width: 19rem;
  background-color: #f1c185;
  box-shadow: 4px 6px 2px #00000029;
}
#update .p-toast-message-content,#install .p-toast-message-content {
  padding: 1rem;
  border-width: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
}

#update .p-toast-icon-close ,#install .p-toast-icon-close{
  color: #212121;
  position: absolute;
  margin-left: 81%;
}
.ui-g {
  width: 100%;
  text-align: center;
}
.ui-g-6{
  width: 50%;
  display: inline-block;
}
#update > div > p-toastitem > div > div > div.install-button-container > div:nth-child(2),
#update > div > p-toastitem > div > div > div.install-button-container > div:nth-child(2) > button,
p-toastitem > div > div > div.ui-g.ng-star-inserted > div:nth-child(2) > button {
  color: #fff;
  background-color: #34A835;
  border: 1px solid #34A835;
}

app-toolbar button{
  background-color: transparent!important;
  border-color: transparent!important;
  margin-top: 4px!important;
  margin-right: 9px!important;
}


mat-tab-header .mat-tab-label {
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: .6;
  min-width: 115px!important;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #fff;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
  color: hsla(0,0%,100%,55%)
}
mat-nav-list > div > mat-list-item > span{
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  height: inherit;
}
mat-list-item > span > span > mat-icon{
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 24px;
  box-sizing: content-box;
  border-radius: 50%;
  padding: 4px;
}
body > kruh-root > mat-sidenav-container > mat-sidenav-content > div.toolbar > div:nth-child(2) > span{
  font-size: 26px;
  color: #f4f4f4;
}

body > div.hero > kruh-root > mat-sidenav-container > mat-sidenav > div > mat-nav-list > h3,
body > div.hero > kruh-root > mat-sidenav-container > mat-sidenav > div > app-menu-item > mat-nav-list > div:nth-child(1) > h3,
body>kruh-root>mat-sidenav-container>mat-sidenav>div>app-menu-item>mat-nav-list h3 {
  font-size: 1.0rem;
  font-weight: 700!important;
  margin: 0 auto!important;
  padding: .25rem .25em!important;
  background-color: #acacac
}
body > div.hero > kruh-root > mat-sidenav-container > mat-sidenav > div > mat-nav-list a,
body > div.hero >kruh-root > mat-sidenav-container > mat-sidenav > div > app-menu-item > mat-nav-list a,
body>kruh-root>mat-sidenav-container>mat-sidenav>div>app-menu-item>mat-nav-list a {
  text-decoration: none;
  color: inherit
}
.mdc-list-item__primary-text{display: contents;}

kruh-program .years{
  display:inline-block;
  width:50%
}
kruh-program .contentratings{
  display:inline-block
}


kruh-program .p-card .p-card-body {
  padding-left: 1rem;
  padding-bottom: 0;
  padding-right: 0;
  padding-top: 0;
}

kruh-program .barn{
    float: right;
    margin-right: 15px;
}

   /* height: 20px; */
@media
/* Fairly small screens including iphones */
only screen and (max-width: 1024px){
kruh-program .p-card .p-card-body {
  padding-left: 0.5rem;
  width: 98%;
}
}

@media only screen and (min-width: 320px) {
  /* Styles for smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}
@media only screen and (min-width: 768px) {
  /* Styles for portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}
@media only screen and (min-width: 992px) {
  /* Styles for tablets, landscape iPad, lo-res laptops ands desktops */
}
@media only screen and (min-width: 1280px) {
  /* Styles for large laptops and desktops */
}


kruh-program form, .flex-column,programm-img-2 {
    display: flex;
    flex-direction: column
}
@media screen and (max-width: 599px) {
  .programm-img-2 {
    display: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .programm-img-2 {
    display: none;
  }
}

.lt-sm-column {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .lt-sm-column {
    flex-direction: row;
  }
}

.flex-column-w86-g20 {
  display: flex;
  flex-direction: column;
  flex: 0 0 86%;
  gap: 20px;
}

.flex-column-g20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (min-width: 960px) {
  .flex-column-g20 {
    flex-direction: row;
  }
}

.flex-column-g8 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media screen and (min-width: 960px) {
  .flex-column-g8 {
    flex: 0 0 67%;
  }
}

kruh-program .p-card .p-card-content {
  padding: 0.1rem 0 0 0;
}
kruh-info .p-card .p-card-content {
  padding:  0 1rem 0 0;
}
app-dashboard .mat-tab-body.mat-tab-body-active kruh-program,  app-dashboard .mat-tab-body.mat-tab-body-active kruh-shop{

  overflow-y: unset;
  }
kruh-program .program-content {
    height: 73vh;
    overflow-y: auto;
    position: relative;
    z-index: 0;
}


kruh-video .content-vh-78 {
  height: 78vh!important;
}
kruh-video #section-gallery{
  z-index: 0;
}
kruh-shop .shop-content {
    height: 73vh;
    overflow-y: auto;
}
kruh-program > p-card >  div.p-card-body > div > div > div > ul{
  margin-top: 0;
}

kruh-shop > p-card > div > div.p-card-body > div > div > div > ul{
  margin-top: 0;
}
kruh-shop > p-card > div > div.p-card-body{
 padding: 0!important;
}
kruh-shop .p-card .p-card-content {
  padding-top: 0;
  padding-bottom: 0;
}


.p-button.p-button-success,.p-buttonset.p-button-success>.p-button,.p-splitbutton.p-button-success>.p-button,update > div > p-toastitem > div > div > div.install-button-container.ng-star-inserted > div:nth-child(2) {
    color: #fff;
    background: #34A835;
    border: 1px solid #34A835
}


.p-button.p-button-success:enabled:hover,.p-buttonset.p-button-success>.p-button:enabled:hover,.p-splitbutton.p-button-success>.p-button:enabled:hover {
    background: #107D11;
    color: #fff;
    border-color: #107d11
}

.p-button.p-button-success:enabled:focus,.p-buttonset.p-button-success>.p-button:enabled:focus,.p-splitbutton.p-button-success>.p-button:enabled:focus {
    box-shadow: 0 0 0 .2rem #aae5aa
}
p-toastitem > div > div > div > div:nth-child(2) > button > span {
  background-color: #34A835;
  border-color: #34A835;
}


.mat-list-base .mat-subheader {
  height: 48px;
  line-height: 16px;
  font-weight: 900;
  color: #000;
  background-color: #93939347;
}

app-toolbar > p-tieredmenu > div{
  width: 6.5em!important;
}
kruh-program form > p-fieldset >fieldset >div{
  overflow-y: auto;
  height: 77vh;
}
kruh-program > p-fieldset >fieldset >div{
  overflow-y: auto;
  height: 77vh;
}


kruh-program div:nth-child(2) p-fieldset > fieldset > div >div >div>p-carousel
{
  margin: 0 auto;
}

kruh-about > p-card > div > div.p-card-body > div > div > form > div> div> span
{
 margin-right: 20px;
}

 kruh-program > p-card > div > div.p-card-body > div > form > div> div> span > i
 {
   z-index: 1;
 }
  kruh-program > p-card > div > div.p-card-body > div >  form > div> div > span > p-autocomplete > span > input
  {padding-left: 35px;max-width: 98%;}
kruh-editorial > p-card > div > div.p-card-body > div > form > div > div:nth-child(2) > span>i,kruh-video > p-card > div > div.p-card-body > div > form > div > div:nth-child(2) > span>i  {
    z-index: 1;
}
kruh-editorial > p-card > div > div.p-card-body > div > form > div > div:nth-child(2) > span > p-autocomplete > span > input
{padding-left: 35px;max-width: 98%;}


kruh-editorial > p-card > div > div.p-card-body > div > form > div,kruh-video > p-card > div > div.p-card-body > div > form > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


kruh-video > p-card > div > div.p-card-body > div > form > div > div:nth-child(2) > span > p-autocomplete > span > input
{padding-left: 35px;max-width: 98%;}
kruh-video > p-card > div > div.p-card-body{
  padding: 0 0.5rem 0 0.5rem!important;
}
kruh-editorial > p-card > div > div.p-card-body > div
{
  padding: 0.1rem 0 0 0;
}
kruh-editorial > p-card > div > div.p-card-body > div > form > div > div:nth-child(2) > span > p-autocomplete > span,
kruh-video > p-card > div > div.p-card-body > div > form > div > div:nth-child(2) > span > p-autocomplete > span{
  display: inherit;
}

kruh-editorial > p-card > div > div.p-card-body > div,kruh-video > p-card > div > div.p-card-body > div{
  padding: 0.3rem 0 0 0!important;
}
kruh-program > p-card >  div> div.p-card-body > div > form > div> div > span .p-autocomplete {
    display: inline!important;
}
kruh-editorial > p-card > div > div.p-card-body > div > div> form{
  z-index: 1;
}
.scrollable-content{z-index: 1;}

kruh-program > p-card > div > div.p-card-body > div > form,kruh-editorial > p-card > div > div.p-card-body > div > form{
  z-index: 10!important;
  position: relative;
}

.ck.ck-editor__main>.ck-editor__editable{
  position: relative;
  max-height: 400px;
}
div.has-error > p-autocomplete > span > input,p-dropdown.has-error > div,div.has-error > textarea{
  color: #000;background-color: #ffb9b9;
}

div.has-error > p-autocomplete > span > input,p-dropdown.has-error > div,div.has-error > textarea{
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ff8d8d;
  border-color: #d90000;
}
#programlist > ul li>div:nth-child(1){
  max-width: 11%;
}
#programlist > ul {
  padding-left: 15px;
  list-style-type: none;
}
textarea.has-error,div.has-error > p-autocomplete > span > input,p-dropdown.has-error > div,div.has-error > textarea,p-multiSelect.has-error > div, ckeditor.has-error  > div > div > div,input.has-error, p-fileupload.has-error > div > div.p-fileupload-buttonbar{
  color: #000;background-color: #ffb9b9;
}

textarea.has-error,div> p-autocomplete > span > input,p-dropdown.has-error > div,div.has-error > textarea,div.has-error > p-calendar > span > input,p-multiSelect.has-error > div, ckeditor.has-error,input.has-error, p-fileupload.has-error > div > div.p-fileupload-buttonbar{
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ff8d8d;
  border-color: #d90000;
}
ckeditor.has-error  > div.ck.ck-reset.ck-editor.ck-rounded-corners > div.ck.ck-editor__main > div.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred{
  color: #000;background-color: #ffb9b9;
}
.p-dialog .p-dialog-content {
    padding: 0.2rem!important;
}
@media
only screen and (min-device-width: 768px) and (max-device-width: 1023px)
{
  #programlist > ul li>div:nth-child(1){
    max-width: 13%;
  }
  #programlist > ul {
    padding-left: 15px;
    list-style-type: none;
  }
}
@media (max-device-width: 767px) 
{
  #programlist > ul li>div{
    max-width: unset!important;
  }

}


app-heart-beat{margin-right: 5px;}

app-heart-beat >div{
  display:inline;vertical-align: bottom;
}

 app-heart-beat linearGradient  > stop:nth-child(3){
  stop-color:#008f91;stop-opacity:1
 }
 app-heart-beat defs> linearGradient:nth-child(1) > stop:nth-child(3)
{
  stop-color:rgb(255,255,255);stop-opacity:1
}
app-toolbar > button{
 padding: 0.429rem 0rem!important;
}
app-toolbar > span{
  vertical-align: bottom;
}
kruh-send-web-push > form{
  background-color: snow;
  padding: 20px 0;
}
kruh-send-web-push a.group {
  margin-top: 8px;
  background-color: rgb(95, 95, 95);
  color: white;
  padding: 6px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: solid 2px rgb(95, 95, 95);
}
kruh-send-web-push .sendto {
margin-left: 27px;
}

.tooltip {
  position: fixed;
  background-color: black;
  border-radius: 4px;
  color: #ffffff;
  font-family: Arial;
  padding: 3px 6px;
  font-size: 13px;
    border: .0625rem solid transparent;
    align-items: center;
    background-color: #fff;
    flex-grow: 1;
    justify-content: center;
z-index: 1001;
    transition: background-color .3s ease-out,border-color .1s ease-out;
}
.tooltip::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid black;
        position: absolute;
        left: calc(50% - 5px);
        top: -5px;
      }
      .dre-icon-wrapper {
        display: inline-flex;
        height: 1.875rem;
        position: relative;
        text-align: left;
        vertical-align: top;
        width: 1.875rem;
    }
      .dre-icon-wrapper__wrapper {
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
    }

  .dre-button__touch-area {
      display: flex
  }
  
  .dre-button__touch-area--small-icon-only {
      margin: -.375rem;
      padding: .375rem
  }
  
  .dre-button__main {
      align-items: center;
      background-color: var(--dre-button__main__background-color,#ff001e);
      color: var(--dre-button__main__text-color,#fff);
      display: inline-flex;
      flex-grow: 1;
      justify-content: center;
      padding-left: var(--dre-button__main__left-padding);
      padding-right: var(--dre-button__main__right-padding);
      transition: background-color .3s ease-out,border-color .1s ease-out
  }
  
  .dre-button__main--small {
      --dre-button__main__left-padding: .5rem;
      --dre-button__main__right-padding: 1rem;
      height: 1.75rem
  }
  
  .dre-button__main--medium {
      --dre-button__main__left-padding: 1rem;
      --dre-button__main__right-padding: 1.5rem;
      height: 2.5rem;
  }
  
  .dre-button__main--small-icon-only {
      --dre-button__main__left-padding: .4375rem;
      --dre-button__main__right-padding: .4375rem
  }
  
  .dre-button__main--small-text-only {
      --dre-button__main__left-padding: 1rem;
      --dre-button__main__right-padding: 1rem
  }
  
  .dre-button__main--medium-icon-only {
      --dre-button__main__left-padding: .625rem;
      --dre-button__main__right-padding: .625rem
  }
  
  .dre-button__main--medium-text-only {
      --dre-button__main__left-padding: 1.5rem;
      --dre-button__main__right-padding: 1.5rem
  }
  
  .dre-button__main--secondary {
      border: .0625rem solid;
      color: var(--dre-button__main__text-color,#000)
  }
  
  .dre-button__main--secondary-on-dark {
      border: .0625rem solid;
      color: var(--dre-button__main__text-color,#fff)
  }
  
  .dre-button__main--tertiary {
      border: .0625rem solid transparent;
      color: var(--dre-button__main__text-color,#c90219)
  }
  
  .dre-button__main--tertiary-pressed {
      border: .0625rem solid
  }
  
  .dre-button__main--tertiary-on-dark {
      border: .0625rem solid transparent;
      color: var(--dre-button__main__text-color,#ff1a34)
  }
  
  .dre-button__main--tertiary-on-dark-pressed {
      border: .0625rem solid
  }
  
  .dre-button__main--secondary,.dre-button__main--secondary-on-dark,.dre-button__main--tertiary,.dre-button__main--tertiary-on-dark {
      background-color: var(--dre-button__main__background-color,transparent);
      padding-left: calc(var(--dre-button__main__left-padding, 0rem) - .0625rem);
      padding-right: calc(var(--dre-button__main__right-padding, 0rem) - .0625rem)
  }
  
  .dre-button__text--small:not(:first-child) {
      margin-left: .25rem
  }
  
  .dre-button__text--medium:not(:first-child) {
      margin-left: .5rem
  }
  
  @media (hover: hover) {
      .dre-button:hover:not(:disabled) {
          --dre-button__main__background-color:#c90219;
          text-decoration: none
      }
  
      .dre-button--tertiary:hover:not(:disabled) {
          --dre-button__main__text-color: #ff001e;
          --dre-button__main__background-color: transparent
      }
  
      .dre-button--tertiary-on-dark:hover:not(:disabled) {
          --dre-button__main__text-color: #c90219;
          --dre-button__main__background-color: transparent
      }
  
      .dre-button--secondary:hover:not(:disabled) {
          --dre-button__main__background-color: #e5e5e5
      }
  
      .dre-button--secondary-on-dark:hover:not(:disabled) {
          --dre-button__main__background-color: #333
      }
  }

  .dre-activity-animation-icon {
    display: block;
    height: 100%;
    position: relative;
    width: 100%
}

.dre-activity-animation-icon__bar {
    animation: dre-activity-animation-icon-bar .9s ease 0s infinite;
    background-color: currentColor;
    bottom: 0;
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    transform-origin: 0 100%;
    width: 100%
}

.dre-activity-animation-icon__bar--first {
    --dre-activity-animation-icon-bar--x: 16.667%
}

.dre-activity-animation-icon__bar--second {
    --dre-activity-animation-icon-bar--x: 41.667%;
    animation-delay: -.6s
}

.dre-activity-animation-icon__bar--third {
    --dre-activity-animation-icon-bar--x: 66.667%;
    animation-delay: -.3s
}

@keyframes dre-activity-animation-icon-bar {
    0% {
        transform: translate(var(--dre-activity-animation-icon-bar--x),-16.667%) scale(.16667,.444)
    }

    50% {
        transform: translate(var(--dre-activity-animation-icon-bar--x),-16.667%) scale(.16667,.222)
    }

    to {
        transform: translate(var(--dre-activity-animation-icon-bar--x),-16.667%) scale(.16667,.667)
    }
}
    